import "./home.css"
import {useEffect, useRef, useState} from "react";
import {useScrollContext} from "../../components/SmoothScroll/scroll-context";
import {isMobile} from 'react-device-detect';
import {Card} from "../../components/card/card";
import {CardOverlay} from "../../components/card/card-overlay";
import {Scrollbar} from "../../components/scrollbar/scrollbar";
import {MagicText} from "../../components/magic-text/magic-text";
import {Badge} from "../../components/badge/badge";
import {SkilltreePopup} from "./skilltree-popup";
import {DronePopup} from "./drone-popup";
import {HyperlifePopup} from "./hyperlife-popup";
import {ParticleLifePopup} from "./particlelife-popup";
import {ElectricPopup} from "./electric-popup";
import {RocketPopup} from "./rocket-popup";
import {TalosPopup} from "./talos-popup";

export const HomePage = ({}) => {
  const {updateScrollData, previous, current, rounded, ratio} = useScrollContext();
  const ref = useRef();
  const backgroundRef = useRef();
  const [popup, setPopup] = useState(null)
  const [appImage, setAppImage] = useState(0)

  useEffect(() => {
    backgroundRef.current.style.setProperty("--scroll", `${rounded}px`)
  }, [rounded])

  useEffect(() => {
    updateScrollData({
      previous, current, rounded, ratio,
      ease: isMobile ? 1 : 0.3,
    })

    ref.current.onmousemove = (e) => {
      for (const card of document.getElementsByClassName("card")) {
        const rect = card.getBoundingClientRect(),
          x = e.clientX - rect.left,
          y = e.clientY - rect.top;
        card.style.setProperty("--mouse-x", `${x}px`);
        card.style.setProperty("--mouse-y", `${y}px`);
      }
    };
  }, [])


  const cycleAppImage = (inc) => {
    const newImg = (appImage + inc + 5) % 5
    setAppImage(newImg)
  }

  return (
    <div className="home-container" ref={ref}>
      <div ref={backgroundRef} className={"background"}/>
      <Scrollbar/>
      <div className={"header-container"}>
        <Card style={{width: "100%"}} rotationEnabled={false} glowAbove={false}>
          <div className={"flip"}>
            <div className={"header"}>
              <div className={"profile"}>
                <img src={require("../../images/background/me.png")} className={"profile-image"}/>
                <script type="text/javascript" defer
                        src="https://cdnjs.buymeacoffee.com/1.0.0/button.prod.min.js"
                        data-name="bmc-button"
                        data-slug="seanjhardy"
                        data-color="#FFDD00"
                        data-emoji="☕"
                        data-font="Inter"
                        data-text="Buy me a coffee"
                        data-outline-color="#000000"
                        data-font-color="#000000" data-coffee-color="#ffffff" />
              </div>
              <div className={"header-body"}>
                <span className={"title"}>Sean Hardy</span>
                <span
                  className={"subtitle"}>Fullstack Developer, <MagicText>Designer</MagicText>, Founder, Engineer</span>
                <span className={"header-text"} style={{marginTop: 10}}>
                Hi 👋 I'm Sean. I'm a 22-year-old developer, passionate about building beautiful, functional products to improve people's lives.
                I'm a fast learner, highly multidisciplinary and I excel at product management, communication, and problem-solving.</span>
                <div style={{flex: 1, minHeight: 20}}/>
                <span className={"header-text"}>BSc Computer Science (1st class) - University of Southampton</span>
                <span className={"header-text"}>A*A*A - Computer Science, Physics, Maths</span>
              </div>
            </div>
            <div className={"badge-display"}>
              <Badge img={require("../../images/icons/javascript.webp")}/>
              <Badge img={require("../../images/icons/typescript.png")}/>
              <Badge img={require("../../images/icons/python.png")}/>
              <Badge img={require("../../images/icons/java.png")}/>

              <Badge img={require("../../images/icons/html.png")}/>
              <Badge img={require("../../images/icons/css.png")}/>
              <Badge img={require("../../images/icons/react-native.png")}/>
              <Badge img={require("../../images/icons/nodejs.png")}/>

              <Badge img={require("../../images/icons/flutter.png")}/>
              <Badge img={require("../../images/icons/figma.png")}/>
              <Badge img={require("../../images/icons/firebase.png")}/>
              <Badge img={require("../../images/icons/mongoDB.png")}/>
              <Badge img={require("../../images/icons/git.png")}/>
            </div>
          </div>
        </Card>
      </div>
      <span className={"title"} style={{marginBottom: 30, zIndex:999}}>PROJECTS</span>
      <div className={"card-view"}>
        <Card allowHide={true} onClick={() => setPopup("skilltree")}>
          <CardOverlay
            title={"Skilltree"}
            info={"Mobile app to gamify self-improvement. 10k downloads, 500+ 5-star reviews."}
            style={{backgroundColor: "#000000", width: "100%"}}
          >
            <video className={"video"} autoPlay loop muted playsInline>
              <source src={require("../../videos/skilltree-card.mp4")} type="video/mp4"/>
            </video>
          </CardOverlay>
        </Card>
        {/*<Card allowHide={true}
              onClick={() => setPopup("talos")}>
          <CardOverlay
            title={"TALOS - Finance planner tool"}
            info={"Frontend UI design practice"}
            style={{backgroundColor: "#000000", width: "100%"}}
          >
            <img src={require("../../images/background/talos.jpg")}
                 style={{width: "100%", height: "100%", objectFit: "cover"}}/>
          </CardOverlay>
        </Card>*/}
        <Card allowHide={true} onClick={() => setPopup("drone")}>
          <CardOverlay
            title={"Drone App"}
            info={"A mobile app to pair with drones for avalanche search and rescue, using google-maps API."}
            style={{backgroundColor: "#000000", width: "100%", height: "100%"}}
          >
            <img src={require("../../images/background/asrv.webp")}
                 style={{width: "100%", height: "100%", objectFit: "cover"}}/>
          </CardOverlay>
        </Card>
        <Card allowHide={true} onClick={() => setPopup("hyperlife")}>
          <CardOverlay
            title={"Cellular evolution"}
            info={"Evolutionary simulation of multicellular organisms with neural nets, simulated nervous systems, and predator/prey relationships"}
            style={{backgroundColor: "#000000", width: "100%", height: "100%"}}
          >
            <video className={"video"} autoPlay loop muted playsInline>
              <source src={require("../../videos/hyper-life.mp4")} type="video/mp4"/>
            </video>
          </CardOverlay>
        </Card>
        <Card allowHide={true} onClick={() => setPopup("particle")}>
          <CardOverlay
            title={"Particle Life"}
            info={"A high-speed simulation of particle interactions, generating life-like behaviour."}
            style={{backgroundColor: "#000000", width: "100%", height: "100%"}}
          >
            <video className={"video"} autoPlay loop muted playsInline>
              <source src={require("../../videos/particle-life.mp4")} type="video/mp4"/>
            </video>
          </CardOverlay>
        </Card>
        <Card allowHide={true} onClick={() => setPopup("electric")}>
          <CardOverlay
            title={"Electric Fields Simulation"}
            info={"A simulation of electric fields with field lines, equipotential lines,and beautiful visualisations"}
            style={{backgroundColor: "#000000", width: "100%", height: "100%"}}
          >
            <video className={"video"} autoPlay loop muted playsInline>
              <source src={require("../../videos/electric-fields.mp4")} type="video/mp4"/>
            </video>
          </CardOverlay>
        </Card>
        <Card allowHide={true} onClick={() => setPopup("rocket")}>
          <CardOverlay
            title={"Rocket Simulation"}
            info={"A 2D rocket builder and flight simulator, with procedural parallax landscapes, realistic collisions and an intuitive UI"}
            style={{backgroundColor: "#000000", width: "100%", height: "100%"}}
          >
            <video className={"video"} autoPlay loop muted playsInline>
              <source src={require("../../videos/rocket-simulation.mp4")} type="video/mp4"/>
            </video>
          </CardOverlay>
        </Card>
        <Card allowHide={true}>
          <CardOverlay
            title={"Autoencoder"}
            info={"An autoencoder neural network for 2.5D environment reconstruction and policy guiding"}
            style={{backgroundColor: "#000000", width: "100%", height: "100%"}}
          >
            <video className={"video"} autoPlay loop muted playsInline>
              <source src={require("../../videos/Autoencoder.mp4")} type="video/mp4"/>
            </video>
          </CardOverlay>
        </Card>
        <Card allowHide={true}>
          <CardOverlay
            title={"GPU accelerated slime simulation"}
            info={"A lifelike particle simulation powered using compute shaders (HLSL)"}
            style={{backgroundColor: "#000000", width: "100%", height: "100%"}}
          >
            <video className={"video"} autoPlay loop muted playsInline>
              <source src={require("../../videos/slime.mp4")} type="video/mp4"/>
            </video>
          </CardOverlay>
        </Card>
        <Card allowHide={true}>
          <CardOverlay
            title={"Bipedal Robot"}
            info={"A bipedal walking robot with depth sensing, movement using inverse kinematics"}
            style={{backgroundColor: "#000000", width: "100%", height: "100%"}}
          >
            <video className={"video"} autoPlay loop muted playsInline>
              <source src={require("../../videos/robot.mp4")} type="video/mp4"/>
            </video>
          </CardOverlay>
        </Card>
        <Card allowHide={true}
              onClick={() => window.open('https://www.oquli.ca/journiii','mywindow')}>
          <CardOverlay
            title={"Oquli Journiii - Project Funding Tool"}
            info={"Project funding tool with a procedurally drawn tree UI"}
            style={{backgroundColor: "#000000", width: "100%"}}
          >
            <img src={require("../../images/background/Journii.png")}
                 style={{width: "100%", height: "100%", objectFit: "cover"}}/>
          </CardOverlay>
        </Card>
      </div>
      <span className={"title"} style={{marginBottom: 30, zIndex:999}}>DESIGNS</span>
      <div className={"bento-grid"}>
        <div className={"bento-item"}>
          <Card allowHide={false}
                style={{width: "100%", height: "100%"}}
                innerStyle={{width: "100%", height: "100%"}}
                rotationEnabled={false}>
            <img src={require("../../images/background/login.png")}
                 style={{width: "100%", height: "100%", objectFit: "cover"}}/>
          </Card>
        </div>
        <div className={"bento-item"}>
          <Card allowHide={false}
                style={{width: "100%", height: "100%"}}
                innerStyle={{width: "100%", height: "100%"}}
                rotationEnabled={false}>
            <img src={require("../../images/background/talos.jpg")}
                 style={{width: "100%", height: "100%", objectFit: "cover"}}/>
          </Card>
        </div>
        <div className={"bento-item"}>
          <Card allowHide={false}
                style={{width: "100%", height: "100%"}}
                innerStyle={{width: "100%", height: "100%"}}
                rotationEnabled={false}>
            <img src={require("../../images/background/skilltree.jpg")}
                 style={{width: "100%", height: "100%", objectFit: "cover"}}/>
          </Card>
        </div>
        <div className={"bento-item"} style={{position: "relative"}}>
          <Card allowHide={false}
                style={{width: "100%", height: "100%"}}
                innerStyle={{width: "100%", height: "100%"}}
                rotationEnabled={false}>
          </Card>
          <div style={{position: "absolute", top: 5, left: 0, zIndex: 999,
            backgroundColor: "transparent", display: "flex", width: "100%", height: "calc(100% - 10px)",
          alignItems: "center", justifyContent: "space-evenly"}}>
            <div className={"moveBtn"} onClick={() => cycleAppImage(-1)}>
              <img src={require("../../images/icons/left-arrow.png")}
                   style={{width: "50%", height: "50%", objectFit: "contain"}}/>
            </div>
            <div style={{height: "100%", aspectRatio: 0.462053, width: undefined, zIndex: 99}}>
              {appImage === 0 && (
                <img src={require("../../images/background/skilltree-app1.jpg")}
                     style={{width: "100%", height: "100%", objectFit: "contain"}}/>
              )}
              {appImage === 1 && (
                <img src={require("../../images/background/skilltree-app2.jpg")}
                     style={{width: "100%", height: "100%", objectFit: "contain"}}/>
              )}
              {appImage === 2 && (
                <img src={require("../../images/background/analytics-minimalist.jpg")}
                     style={{width: "100%", height: "100%", objectFit: "contain"}}/>
              )}
              {appImage === 3 && (
                <img src={require("../../images/background/shop.jpg")}
                     style={{width: "100%", height: "100%", objectFit: "contain"}}/>
              )}
              {appImage === 4 && (
                <img src={require("../../images/background/meditation.jpg")}
                     style={{width: "100%", height: "100%", objectFit: "contain"}}/>
              )}
            </div>
            <div className={"moveBtn"} onClick={() => cycleAppImage(1)}>
              <img src={require("../../images/icons/right-arrow.png")}
                   style={{width: "50%", height: "50%", objectFit: "contain"}}/>
            </div>
          </div>
        </div>
      </div>
      <SkilltreePopup show={popup === "skilltree"} onClose={() => setPopup(null)}/>
      <TalosPopup show={popup === "talos"} onClose={() => setPopup(null)}/>
      <DronePopup show={popup === "drone"} onClose={() => setPopup(null)}/>
      <HyperlifePopup show={popup === "hyperlife"} onClose={() => setPopup(null)}/>
      <ParticleLifePopup show={popup === "particle"} onClose={() => setPopup(null)}/>
      <ElectricPopup show={popup === "electric"} onClose={() => setPopup(null)}/>
      <RocketPopup show={popup === "rocket"} onClose={() => setPopup(null)}/>
    </div>
  )
}