import {Popup} from "../../components/popup/popup";
import {useEffect, useState} from "react";
import {ProjectPopup} from "./project-popup";

export const TalosPopup = ({show, onClose}) => {
  const [maxImageWidth, setMaxImageWidth] = useState("60%");

  const onResize = () => {
    setMaxImageWidth(window.innerWidth < 900 ? "100%" : "60%")
  }

  useEffect(() => {
    window.addEventListener("resize", onResize)
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])

  return (
    <Popup show={show} onClose={onClose} style={{width: "70%", minWidth: 400, position: "relative"}} >
      <div className={"close-btn"} onClick={onClose}>
        <img src={require("../../images/icons/close.png")} style={{width: 20, height: 20, objectFit: "contain"}}/>
      </div>
      <ProjectPopup>
        <div style={{flex: 1, display: "flex", flexDirection: "column"}}>
          <div className={"popup-header"}>
            <span className={"subtitle"}
                  style={{fontSize: 25}}>ASRV - Drone app</span>
          </div>
          <div style={{overflow: "hidden", padding: 10, display:"flex", flexDirection: "column", flex: 1, alignItems: "flex-start"}}>
            <div className={"scrollbox"}>
              <span style={{fontFamily: "Roboto"}}>
                A fictional UI design.
              </span>
            </div>
          </div>
        </div>
        <img src={require("../../images/background/talos.jpg")}
             className={"popup-video-card"}
             style={{aspectRatio: 2, maxWidth: maxImageWidth, objectFit: "cover"}}/>
      </ProjectPopup>
    </Popup>
  )
}